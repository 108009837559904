import React from "react"
import { PageProps } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/templates/Layout/Layout"
import PageWidth from "../components/templates/PageWidth/PageWidth"
import SEO from "../components/atoms/SEO/SEO"
import SmartLink from "../components/atoms/SmartLink/SmartLink"
import Login from "../features/Login/Login"
import LoginDefault from "../features/Login/LoginDefault"

const LoginPage = (props: PageProps) => (
  <Layout>
    <SEO title="Login" />
    <PageWidth>
      <Router basepath="/login">
        <Login path="/:token" />
        <LoginDefault path="/" />
      </Router>
    </PageWidth>
  </Layout>
)

export default LoginPage
