import React from "react"
import SmartLink from "../../components/atoms/SmartLink/SmartLink"

interface Props {
  path: string
}

const LoginDefault = (props: Props) => (
  <>
    <h1>That was not quite right</h1>
    <p>
      This link can only be opened, when you have the mobile app on your device.
    </p>
    <SmartLink to="https://www.prooftimetracking.com">Go get it</SmartLink>
  </>
)

export default LoginDefault
