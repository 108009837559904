import React from "react"
import SmartLink from "../../components/atoms/SmartLink/SmartLink"

interface Props {
  path: string
}

const Login = (props: Props) => (
  <>
    <h1>Get the app</h1>
    <p>
      This link can only be opened, when you have the mobile app installed on
      your device.
    </p>
    <SmartLink to="https://www.prooftimetracking.com">Go get it</SmartLink>
  </>
)

export default Login
